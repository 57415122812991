import React, { useContext, useState, useEffect } from "react";
import GlobalState from "../../contexts/GlobalState";
import ClustersConcorrentes from "./ClustersConcorrentes";
import SensibilidadeProdutos from "./SensibilidadeProdutos";
/**
 * clusterConcorrente
 * sensibilidadeProdutos
 * politicaDePreços 
 * escalaEntreMarcas
 * arredondamentos
 * proporcionalidades 
 * precoAtacado2preco
 * cadastroDeConcorrentes
 * papelDeCategoria
 * margensObjetivas
 * toleranciaTrocaDePreco
 * precosMinMax
 * associacaodeItens
 * 
*/
export default function Parametros() {
    const state = useContext(GlobalState)[0];

    const [selected, setSelected] = useState('');

    async function linkToDashboard(email) {
        const requestClick = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email }),
        };

        const response = await (
            await fetch(`${process.env.REACT_APP_API}/api/v2/qlik-url`, requestClick)
        ).json();
        console.log(response);
        if (response) {
            return window.open(response.url);
        } else {
            alert(`HTTP-Error: ${response.status} - ${response.statusText}`);
        }
    }

    return (
        <div>
            <div className="bgBlue"></div>
            <div className="container-fluid">
                <div className="row align-items-end pl-4">
                    <p className="h1 text-white">Parâmetros</p>
                    {/* <p id="sub" className="h2 text-white  text-opacity-75 pl-5" style={{ opacity: 0.7 }}> Subtitulo</p> */}
                </div>
                <div className="bg-white mx-5 py-4 px-1">
                    <div className="row  mx-1 pt-1 px-1">

                        <div className="col">
                            <button className="btn-param" onClick={() => 
                            setSelected('clusterConcorrente')
                            }>
                                Cluster e concorrentes
                            </button> 
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => 
                                setSelected('sensibilidadeProdutos')
                            }>
                                Sensibilidade de produtos
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                setSelected('politicaDePreços')
                            }}>
                                Politicas de preços
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Escala entre marcas";
                            }}>
                                Escala entre marcas
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Arredondamentos";
                            }}>
                                Arredondamentos
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Proporcionalidades";
                            }}>
                                Proporcionalidades
                            </button>

                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Preço atacado (2° preço)";
                            }}>
                                Preço atacado (2° preço)
                            </button>
                        </div>
                    </div>
                    <div className="row  mx-1 pt-1 px-1">

                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Cadastro de concorrentes";
                            }}>
                                Cadastro de concorrentes
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Papel de categoria";
                            }}>
                                Papel de categoria
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Margens objetivas";
                            }}>
                                Margens objetivas
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Tolerância troca de preços";
                            }}>
                                Tolerância troca de preços
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Preços mínimos e máximos";
                            }}>
                                Preços mínimos e máximos
                            </button>
                        </div>
                        <div className="col">
                            <button className="btn-param" onClick={() => {
                                document.getElementById("sub").value = "Associação de itens";
                            }}>
                                Associação de itens
                            </button>
                        </div>
                        <div className="col">
                        </div>
                    </div>
                </div>
                <div className="flex bg-white mx-5 mt-3 p-4 px-1">
                    {selected === 'clusterConcorrente' && <ClustersConcorrentes></ClustersConcorrentes>}
                    {selected === 'sensibilidadeProdutos' && <SensibilidadeProdutos></SensibilidadeProdutos>}
                </div>
            </div>

        </div>
    );
}
