import React from "react";

export function renderCheckboxSliderModule(
  row,
  index,
  rows,
  filhos,
  bloqueados,
  handleCheckboxSlider,
  difTotal
) {
  let selecionado = ""; //! var

  if (!rows[index]) {
    return;
  }

  if (rows[index].analizado == 2) {
    selecionado = "selecionado ";
  }
  if (rows[index].analizado == 1) {
    return;
  }
  if (
    (row.flag_pai_ou_filho === "FILHO" || filhos === "FILHO") &&
    bloqueados === false
  ) {
    return;
  }
  if (difTotal === true) {
    return (
      <span
        className={selecionado + "checkbox--custom box_vlr"}
        key={index + "checkbox_analisado2"}
        onClick={(e) => handleCheckboxSlider(e, index)}
      ></span>
    );
  }
}
