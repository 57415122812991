import React from "react";
import { sanitalize } from "../../shared/utils/sanitalize";

export function SetasNovoPrecoModule(valor, row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  // const flag_pai_ou_filho = (row.flag_pai_ou_filho);
  const preco_min_max = sanitalize(row.preco_min_max);
  if (valor > preco_atual_varejo) {
    return (
      <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
    );
  }
  if (valor < preco_atual_varejo) {
    return (
      <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
    );
  }
  if (valor == preco_atual_varejo) {
    return (
      <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
    );
  }
  if (preco_atual_varejo > preco_min_max) {
    return (
      <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
    );
  }
  if (preco_atual_varejo < preco_min_max) {
    return (
      <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
    );
  }
  if (preco_atual_varejo === preco_min_max) {
    return (
      <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
    );
  }
}
