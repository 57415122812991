import React, { } from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import Accountform from './Forms/Accountform';


function Login() {


    return (
        <>
        <Accountform/>
        </>

    )
} 

export default withAuthenticator(Login)