import React from 'react';
import "./style.css"

export default function DownloadArqCsv() {

    const download = async() =>{
        window.location = process.env.REACT_APP_API+"/api/v2/gestao/file-download-zip"
    }

    return (
        <div class="csvDownload" onClick={download}>
            Download dos Arquivos CSV
        </div>
      )
  }