import React from "react";
export function renderBloqueadosModule(row, index, difTotal) {
  if (
    row.analizado == 2 &&
    row.flag_pai_ou_filho === "PAI" &&
    difTotal === false
  ) {
    return <span className="glyphicon glyphicon-ban-circle"></span>;
  }
}
