import React from "react";
import { HiPlus } from 'react-icons/hi';
import { FiEdit2 } from 'react-icons/fi';

export default function ClustersConcorrentes() {
    return (
        <div className="Containt">
            <h4 className="pb-4 clean">Cluster e concorrente</h4>

            <div className="mb-3">
                <label className="corText" htmlFor="">Cluster</label>
                <div className="row bgGray element">
                    <select name="cluster" id="cluster" className="col-6 p-2 corText">
                        <option value="" selected>Selecione</option>
                        <option value="1">São Paulo Centro</option>
                        <option value="2">São Paulo Interior</option>
                        <option value="3">São Paulo Litoral</option>
                        <option value="4">Minas Gerais</option>
                        <option value="5">Rio de Janeiro</option>
                    </select>
                    <div className="row align-items-center col-md-2 col-md-offset-4">
                        <HiPlus className=" corIcon ml-5 mr-4" style={{cursor:'pointer'}}></HiPlus>
                        <FiEdit2 className="corIcon " style={{cursor:'pointer'}}></FiEdit2>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label className="corText" htmlFor="">Lista de pesquisa</label>
                <div className="row  bgGray element">
                    <select name="cluster" id="cluster" className="col-6 p-2 corText">
                        <option value="" selected>Selecione</option>
                        <option value="1">Cluster 1</option>
                        <option value="2">Cluster 2</option>
                        <option value="3">Cluster 3</option>
                        <option value="4">Cluster 4</option>
                        <option value="5">Cluster 5</option>
                    </select>
                    <div className="row align-items-center col-md-2 col-md-offset-4">
                        <HiPlus className=" corIcon ml-5 mr-4" style={{cursor:'pointer'}}></HiPlus>
                        <FiEdit2 className="corIcon" style={{cursor:'pointer'}}></FiEdit2>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label className="corText" htmlFor="">Margem objetiva</label>
                <div className="row bgGray element">
                    <select name="cluster" id="cluster" className="col-6 p-2 corText">
                        <option value="" selected>Selecione</option>
                        <option value="1">Mix Varejo</option>
                        <option value="2">Mix Atacado</option>
                    </select>
                    <div className="row align-items-center col-md-2 col-md-offset-4">
                        <HiPlus className=" corIcon ml-5 mr-4" style={{cursor:'pointer'}}></HiPlus>
                        <FiEdit2 className="corIcon" style={{cursor:'pointer'}}></FiEdit2>
                    </div>
                </div>
            </div>

            <h4 className="mt-5 pb-4 clean">Concorrentes</h4>
            <div className="d-flex justify-content-center">
                <div className="corIcon bgGray" style={
                    {
                        height: '50px',
                        width: '50px',
                        borderRadius: '25px',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems:"center",
                        justifyContent: 'center',
                        cursor:'pointer'
                    }}>
                    <HiPlus ></HiPlus>
                </div>
            </div>

        </div>
    )
}