export function renderBackgroundColorModule(exportar, difTotal, bloqueados) {
  if (exportar === true) {
    return "cinza_";
  }
  if (difTotal === true && bloqueados === false) {
    return "azul_ceu";
  }
  if (bloqueados === true) {
    return "salmao";
  }

  return "branco";
}
