export const flags = [
  "ULTRABOX",
  "GIGANTE",
  "COMBO",
  "Desco",
  "NAG-HIBRIDO",
  "MERC-ATACAREJO",
  "ECOMIX",
  "MACROMIX",
  "COMPREMAIS",
  "LAG",
  "Mayorista",
  "Varejo-Atacarejo",
  "Atacado",
];
