export function statusItem(status) {
  var cor = "";
  switch (status) {
    case "INATIVO":
      cor = "vermelho";
      break;
    case "SUSPENSO":
      cor = "vermelho";
      break;
    case "FORA DE LINHA":
      cor = "vermelho";
      break;

    default:
      break;
  }
  return cor;
}
