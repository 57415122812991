import { makeStyles } from "@material-ui/core/styles";

export const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  stickyTableCell: {
    borderBottom: "none",
  },
}));
