function sanitalize(valor) {
  if (!valor) return;
  return parseFloat(valor.replace(",", "."));
}

export function MargemAtualCor(row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const custo_do_produto = sanitalize(row.custo_do_produto);
  const impostos_simulador = sanitalize(row.impostos_simulador);
  const resultado =
    (preco_atual_varejo * ((100 - impostos_simulador) / 100) -
      custo_do_produto) /
    preco_atual_varejo;
  if (resultado < 0) {
    return "vermelho_font";
  }
  return resultado;
}

export function NovaMargem(valor, row) {
  const custo_do_produto = sanitalize(row.custo_do_produto);
  const impostos_simulador = sanitalize(row.impostos_simulador);
  const resultado =
    ((valor * ((100 - impostos_simulador) / 100) - custo_do_produto) / valor) *
    100;
  return resultado;
}
export function NovaMargemDefault(row) {
  const preco_min_max = sanitalize(row.preco_decisao || row.preco_min_max);
  const custo_do_produto = sanitalize(row.custo_do_produto);
  const impostos_simulador = sanitalize(row.impostos_simulador);
  const resultado =
    ((preco_min_max * ((100 - impostos_simulador) / 100) - custo_do_produto) /
      preco_min_max) *
    100;
  return resultado;
}
export function NovaMargemCor(resultado) {
  if (resultado < 0) {
    return "vermelho_font";
  }
  return resultado;
}

export function VariacaoNovoPreco(valor, row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const resultado = (valor / preco_atual_varejo - 1) * 100;
  return resultado;
}
export function VariacaoNovoPrecoDefault(row) {
  const preco_min_max = sanitalize(row.preco_decisao || row.preco_min_max);
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const resultado = (preco_min_max / preco_atual_varejo - 1) * 100;
  return resultado;
}

export function IndiceAtual(row) {
  const preco_referencia = sanitalize(row.preco_referencia);
  if (preco_referencia === 0) {
    return "";
  }
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const resultado =
    ((preco_atual_varejo - preco_referencia) /
      Math.max(preco_atual_varejo, preco_referencia) +
      1) *
    100;

  return resultado;
}

export function QuantidadeVendaEstimada(valor, row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const vendas = sanitalize(row.vendas);
  const resultado = valor - preco_atual_varejo;

  let quantidadeEstimada = 0;

  if (resultado === 0) {
    return vendas;
  } else if (resultado > 0) {
    switch (row.sensibilidade_simulador) {
      case "SUPER SENSÍVEL":
        quantidadeEstimada = vendas * 0.9;
        break;
      case "SENSÍVEL":
        quantidadeEstimada = vendas * 0.95;
        break;
      case "TOP NÃO SENSÍVEL":
        quantidadeEstimada = vendas * 0.99;
        break;
      case "PRIMEIRO-PREÇO":
        quantidadeEstimada = vendas * 0.9;
        break;
      case "MIX MARGEM":
        quantidadeEstimada = vendas * 1.0;
        break;
    }
  } else {
    switch (row.sensibilidade_simulador) {
      case "SUPER SENSÍVEL":
        quantidadeEstimada = vendas * 1.1;
        break;
      case "SENSÍVEL":
        quantidadeEstimada = vendas * 1.05;
        break;
      case "TOP NÃO SENSÍVEL":
        quantidadeEstimada = vendas * 1.01;
        break;
      case "PRIMEIRO-PREÇO":
        quantidadeEstimada = vendas * 1.1;
        break;
      case "MIX MARGEM":
        quantidadeEstimada = vendas * 1.0;
        break;
    }
  }
  return quantidadeEstimada;
}
export function QuantidadeVendaEstimadaDefault(row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const preco_min_max = sanitalize(row.preco_min_max);
  const resultado = preco_min_max - preco_atual_varejo;
  const vendas = sanitalize(row.vendas);
  let quantidadeEstimada = 0;

  if (resultado === 0) {
    return vendas;
  } else if (resultado > 0) {
    switch (row.sensibilidade_simulador) {
      case "SUPER SENSÍVEL":
        quantidadeEstimada = vendas * 0.9;
        break;
      case "SENSÍVEL":
        quantidadeEstimada = vendas * 0.95;
        break;
      case "TOP NÃO SENSÍVEL":
        quantidadeEstimada = vendas * 0.99;
        break;
      case "PRIMEIRO-PREÇO":
        quantidadeEstimada = vendas * 0.9;
        break;
      case "MIX MARGEM":
        quantidadeEstimada = vendas * 1.0;
        break;
    }
  } else {
    switch (row.sensibilidade_simulador) {
      case "SUPER SENSÍVEL":
        quantidadeEstimada = vendas * 1.1;
        break;
      case "SENSÍVEL":
        quantidadeEstimada = vendas * 1.05;
        break;
      case "TOP NÃO SENSÍVEL":
        quantidadeEstimada = vendas * 1.01;
        break;
      case "PRIMEIRO-PREÇO":
        quantidadeEstimada = vendas * 1.1;
        break;
      case "MIX MARGEM":
        quantidadeEstimada = vendas * 1.0;
        break;
    }
  }
  return quantidadeEstimada;
}

export function IndiceNovoPreco(valor, row) {
  const preco_referencia = sanitalize(row.preco_referencia);
  if (preco_referencia === 0) {
    return "";
  }
  const resultado =
    ((valor - preco_referencia) / Math.max(valor, preco_referencia) + 1) * 100;
  return resultado;
}
export function IndiceNovoPrecoDefault(row) {
  const preco_referencia = sanitalize(row.preco_referencia);
  if (preco_referencia === 0) {
    return "";
  }
  const preco_min_max = sanitalize(row.preco_min_max);
  const resultado =
    ((preco_min_max - preco_referencia) /
      Math.max(preco_min_max, preco_referencia) +
      1) *
    100;
  return resultado;
}

export function DiferencaUnitario(valor, row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const resultado = valor - preco_atual_varejo;
  return resultado;
}
export function DiferencaUnitarioDefault(row) {
  const preco_min_max = sanitalize(row.preco_min_max);
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const resultado = preco_min_max - preco_atual_varejo;

  return resultado;
}

export function PrecoMedioConc(row) {
  const preco_primario = sanitalize(row.preco_primario);
  const preco_secundario = sanitalize(row.preco_secundario);
  const preco_terciario = sanitalize(row.preco_terciario);
  const preco_quaternario = sanitalize(row.preco_quaternario);
  const preco_quinto = sanitalize(row.preco_quinto);
  const preco_sexto = sanitalize(row.preco_sexto);
  const preco_setimo = sanitalize(row.preco_setimo);
  const preco_octonario = sanitalize(row.preco_octonario);
  const preco_nonario = sanitalize(row.preco_nonario);
  const preco_decenario = sanitalize(row.preco_decenario);
  var quantidade = 0;
  const precos = [
    preco_primario,
    preco_secundario,
    preco_terciario,
    preco_quaternario,
    preco_quinto,
    preco_sexto,
    preco_setimo,
    preco_octonario,
    preco_nonario,
    preco_decenario,
  ];

  let valor = 0;
  precos.forEach((preco) => {
    return preco > 0 ? (quantidade++, (valor += preco)) : null;
  });
  return valor / quantidade;
}

export function pmz(row) {
  const custo_do_produto = row.custo_do_produto;
  const impostos_simulador = row.impostos_simulador;
  const resultado = custo_do_produto / ((100 - impostos_simulador) / 100);
  return resultado;
}

export function DiferencaTotal(row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const vendas = sanitalize(row.vendas);
  const preco_min_max = sanitalize(row.preco_min_max);
  const margematual = sanitalize(row.margematual);
  const novamargem = sanitalize(row.novamargem);
  const qtde_venda_estimada = row.qtde_venda_estimada;
  const resultado =
    preco_min_max * qtde_venda_estimada * (novamargem / 100) -
    preco_atual_varejo * vendas * (margematual / 100);
  if (margematual === novamargem) {
    return "0";
  }
  return resultado;
}
export function DiferencaTotalNovo(valor, row) {
  const preco_atual_varejo = sanitalize(row.preco_atual_varejo);
  const vendas = sanitalize(row.vendas);
  const margematual = sanitalize(row.margematual);
  const novamargem = sanitalize(row.novamargem);
  const qtde_venda_estimada = row.qtde_venda_estimada;
  const resultado =
    valor * qtde_venda_estimada * (novamargem / 100) -
    preco_atual_varejo * vendas * (margematual / 100);
  if (margematual === novamargem) {
    return "0";
  }
  return resultado;
}

function percentage(partialValue, totalValue) {
  console.log({
    preco_decisao: partialValue,
    preco_atual_varejo: totalValue,
    calc1: 100 * partialValue,
    calc2: (100 * partialValue) / totalValue,
    finalValue: ((100 * partialValue) / totalValue).toFixed(2),
  });
  return ((100 * partialValue) / totalValue).toFixed(2);
}
export function valorValido(row) {
  const porcentagem = percentage(
    row.preco_decisao ?? row.preco_min_max,
    row.preco_atual_varejo
  );
  if (porcentagem > 150) {
    return "amarelo";
  } else if (porcentagem < 50) {
    return "vermelho";
  }
  return "normal";
}

export function valorValidoAtacado(row, atacadistas = false) {
  const valorAtacado = row.preco_decisao_atacado;
  const valorVarejo = row.preco_decisao ?? row.preco_min_max;
  console.log({
    preco_decisao: row.preco_decisao,
    preco_min_max: row.preco_min_max,
    sku: row.codigo_filhos,
  });
  if (atacadistas) {
    /*
    em clientes que entram regra de atacadistas ex: 'roldao' 
    o valor atacado vem pela coluna preco_decisao 
    e o valor varejo vem pela coluna preco_decisao_atacado
    */
    if (valorVarejo > valorAtacado) {
      return "vermelho";
    }
  } else {
    if (valorAtacado > valorVarejo) {
      return "vermelho";
    }
  }
  if (valorVarejo === valorAtacado) {
    return "amarelo";
  }
  return "normal";
}

export function valorValido2(valor) {
  console.log(valor);
  if (valor > 15) {
    return "vermelho";
  }
  if (valor < -15) {
    return "vermelho";
  }
  return "normal";
}
