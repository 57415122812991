//Venda Margem Tipo

import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';
import Chart from "react-google-charts";




function VendaMargemTipo(props) {
    const [vendaMargemTipo, setVendaMargemTipo] = useState({});
  
    useEffect(() => {

        async function fetchMyAPI() {

            const VendaMargemTipoObj = {db_schema: 'pricing_bigbox'}
            const apiVendaMargemTipo = await fetch(props.apiUrl + "/gestao-precos-dashboard/vendas-tipo", {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                method: "POST",
                body: itensFiltro (VendaMargemTipoObj)
            });
            const VendaMargemTipoAPI = await apiVendaMargemTipo.json();

                var data = []
                data.push([{ type: 'string', label: 'Tipo de Preço' }, { type: 'string', label: 'Valor' }, 
                { type: 'number', label: 'Participação' }, { type: 'number', label: 'Margem'}, 
                { type: 'number', label: 'Margem Percentual'}, { type: 'number', label: 'Contribuição'}])
                VendaMargemTipoAPI.data.forEach(loja => {
                    data.push([
                            loja.tipo_oferta, 
                            {v: loja.valor, f:parseFloat((parseFloat(loja.valor/1000000).toFixed(0)))+ 'M'},
                            parseFloat( loja.participacao).toFixed(1),
                            {v: loja.margem, f:parseFloat((parseFloat(loja.margem/100000).toFixed(0)))+ 'M'},                        
                            parseFloat( loja.margem_percentual).toFixed(1),
                            parseFloat(loja.contribuicao).toFixed(1)
                    ])

                });
            setVendaMargemTipo(data)

            await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    return (
        <Chart
            width={'100%'}
            height={'300px'}
            chartType="Table"
            loader={<div>Loading Chart</div>}
            data={vendaMargemTipo}
            options={{
                showRowNumber: true,
            }}
            rootProps={{ 'data-testid': '1' }}
        />
    );
}






  export default  VendaMargemTipo;
  

  