//Índice de Competitividade vs Concorrentes

import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';
import {Bar} from 'react-chartjs-2';




function CompXConc(props) {
    const [competiv, setCompetiv] = useState({});
  
    useEffect(() => {

        async function fetchMyAPI() {

            const CompXConcObj = {db_schema: 'pricing_bigbox'}
            const apiCompXConc = await fetch(props.apiUrl + "gestao-precos-dashboard/indice-preco-concorrente", {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                method: "POST",
                body: itensFiltro (CompXConcObj)
            });
            const CompXConcAPI = await apiCompXConc.json();

            var nomes = [];
            var media = [];
            
            CompXConcAPI.data.forEach(loja => {
                nomes.push(loja.nome_loja_concorrente)
                media.push( (loja.media_concorrente*100).toFixed(1) )
            });
            
            const data = {
                        labels: nomes,
                        datasets: [
                            {
                            label: 'Média Concorrente',
                            backgroundColor: '#c7c5de',
                            borderColor: '#372fa3',
                            borderWidth: 1,
                            hoverBackgroundColor: '#4285F4',
                            hoverBorderColor: '#372fa3',
                            data: media
                            }
                        ]
            };

            setCompetiv(data);

            await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    return (
             <Bar
                data={competiv}
                width={100}
                height={500}
                options={{
                    maintainAspectRatio: false
                }}
            />
    );
  }






  export default  CompXConc;
  

  