export const filtrosDefault = {
  grupo: null,
  fornecedor: null,
  produto: null,
  bandeira: null,
  sensibilidade: null,
  tem_pesquisa: null,
  papel_categoria: null,
  sub_grupo: null,
  cluster: null,
  departamento: null,
  secao: null,
  escala: null,
  comprador: null,
  registros: "150",
  pagina: "1",
  db_schema: "",
  tipo: "pais",
  analizados: 0,
};
