import React, { createContext, useCallback, useContext, useState } from "react";
import api from "../services/api";
const AuthContext = createContext({});
const AuthProvider = ({ children }) => {
  const user = localStorage.getItem("@PR:User");
  const [data, setData] = useState(() => {
    if (user) {
      const userData = JSON.parse(user);
      return {
        ...userData,
      };
    }
    return null;
  });

  const [tempData, setTempData] = useState(() => {
    const temp = localStorage.getItem("@PR:tempData");
    if (temp) {
      const tempValue = JSON.parse(temp);
      return {
        ...tempValue,
      };
    }
    return null;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post("/api/users/login", { email, password });
    console.log(response, response.status, response.data.data.message);
    if (
      response.status === 200 &&
      response.data.error &&
      response.data.data.message === "Senha expirada"
    ) {
      
      const { email, token } = response.data.data.data;
      localStorage.setItem("@PR:tempData", JSON.stringify({ email, token }));
      setTempData({ email });
      return { success: true, resetPassword: true };
    }
    if (response.status === 200 && !response.data.error) {
      localStorage.removeItem("@PR:tempData");
      const { user: nUser } = response.data.data;
      localStorage.setItem("@PR:User", JSON.stringify({ ...nUser }));
      setData({
        ...nUser,
      });
      return { success: true, resetPassword: false };
    }

    return { success: false, resetPassword: false };
  }, []);

  const resetPassword = useCallback(
    async (password) => {
      const response = await api.put("/api/users/change-password", {
        email: tempData.email,
        token: tempData.token,
        password,
      });
      if (response.status === 200 && !response.data.error) {
        localStorage.setItem("@PR:User", JSON.stringify({ ...tempData }));
        await signIn({ email: tempData.email, password });
      }
      return true;
    },
    [tempData]
  );

  const signOut = useCallback(() => {
    localStorage.clear();
    setData(null);
  }, []);

  const userLogged = useCallback(() => {
    return user ? true : false;
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user: data,
        signIn,
        resetPassword,
        userLogged,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
export { useAuth, AuthProvider };
