import React from 'react'
import { Map, Marker, Popup, TileLayer} from 'react-leaflet'
import L from 'leaflet'


var blueIcon = new L.Icon({
	iconUrl: '/image_icons/marker-icon-blue.png',
	shadowUrl: '/image_icons/marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

var redIcon = new L.Icon({
  iconUrl: '/image_icons/marker-icon-red.png',
  shadowUrl: '/image_icons/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});




  var markers = [
    { key: 'marker1', icon: blueIcon, position: [-15.8317806, -47.9131305], content: 'ULTRABOX 11 - ITAPOA' },
    { key: 'marker2', icon: blueIcon, position: [-15.779911, -47.8766529], content: 'BIG BOX 02-402 NORTE' },
    { key: 'marker3', icon: blueIcon, position: [-15.8319609, -47.9130344], content: 'BIG BOX 03-413 SUL' },
    { key: 'marker4', icon: blueIcon, position: [-15.7975686, -47.9226951], content: 'BIG BOX 04-301 SW' },
    { key: 'marker5', icon: blueIcon, position: [-15.7625421, 47.8870564], content: 'BIG BOX 05-408 NORTE' },
    { key: 'marker6', icon: blueIcon, position: [-15.7444938, -47.8481976], content: 'BIG BOX 06-SIBERIA' },
    { key: 'marker7', icon: blueIcon, position: [-15.8158456, -48.012622], content: 'BIG BOX 07-VIA PARK' },
    { key: 'marker8', icon: blueIcon, position: [-15.8038731, -47.8981791], content: 'BIG BOX 08-503 SUL' },
    { key: 'marker9', icon: blueIcon, position: [-15.7209062, -47.8851303], content: 'BIG BOX 09-PENINSULA' },
    { key: 'marker10', icon: blueIcon, position: [-15.8372271, -48.0346996], content: 'BIG BOX 10-CARMO' },
    { key: 'marker11', icon: blueIcon, position: [-15.7682274, -47.8914107], content: 'BIG BOX 11-310 NORTE' },
    { key: 'marker12', icon: blueIcon, position: [-15.7632953, -47.8810811], content: 'BIG BOX 12-208 NORTE' },
    { key: 'marker13', icon: blueIcon, position: [-15.7711173, -47.8869405], content: 'BIG BOX 01-106 NORTE' },
    { key: 'marker14', icon: blueIcon, position: [-15.7335186, -47.8669831], content: 'BIG BOX 13-QI 05' },
    { key: 'marker15', icon: blueIcon, position: [-15.8138024, -47.9094097], content: 'BIG BOX 14-508 SUL' },
    { key: 'marker16', icon: blueIcon, position: [-15.8071718, -47.9354137], content: 'BIG BOX 15-105 SW' },
    { key: 'marker17', icon: blueIcon, position: [-15.8203509, -47.9186316], content: 'BIG BOX 16-512 SUL' },
    { key: 'marker18', icon: blueIcon, position: [-15.7879702, -47.9879492], content: 'ULTRABOX 10 - ESTRUTURAL' },
    { key: 'marker19', icon: blueIcon, position: [-15.8020329, -48.1249082], content: 'ULTRABOX 09 - CEILANDIA NORTE' },
    { key: 'marker20', icon: blueIcon, position: [-15.6394701, -47.6537912], content: 'ULTRABOX 08 - ARAPOANGA' },
    { key: 'marker21', icon: blueIcon, position: [-15.8763498, -48.0218763], content: 'ULTRABOX 07 - ADE' },
    { key: 'marker22', icon: blueIcon, position: [-15.6481951, -47.8014983], content: 'ULTRABOX 06 - SOBRADINHO' },
    { key: 'marker23', icon: blueIcon, position: [-16.0483173, -47.9722517], content: 'ULTRABOX 05 - POLO JK' },
    { key: 'marker24', icon: blueIcon, position: [-15.836693, -48.1127043], content: 'ULTRABOX 04 - CEILANDIA' },
    { key: 'marker25', icon: blueIcon, position: [-15.658297, -47.8446037], content: 'ULTRABOX 03 - COLORADO' },
    { key: 'marker26', icon: blueIcon, position: [-15.6022096, -47.6836934], content: 'ULTRABOX 01 - PLANALTINA' },
    { key: 'marker27', icon: blueIcon, position: [-15.8346854, -47.8899108], content: 'BIG BOX 17-LAGO SUL' },
    { key: 'marker28', icon: blueIcon, position: [-15.965806, -48.0249496], content: 'ULTRABOX 02 - GAMA' },

    { key: 'marker29', icon: redIcon, position: [-15.7749622, -47.8875657], content: 'CARREFOUR BAIRRO-ASA NORTE' },
    { key: 'marker30', icon: redIcon, position: [15.654987335205078, -47.782161712646484], content: 'ATACADÃO DIA A DIA' },
    { key: 'marker31', icon: redIcon, position: [-15.8145319, -47.9073713], content: 'PAO DE AÇÚCAR' },
    { key: 'marker32', icon: redIcon, position: [-15.69395923614502, -47.86963653564453], content: 'COSTA ATACADÃO' },
    { key: 'marker33', icon: redIcon, position: [-15.7383724, -47.8885942], content: 'PRA VOCÊ SUPERMERCADOS' },
    { key: 'marker34', icon: redIcon, position: [-15.6110594, -47.6540121], content: 'COMPER SUPERMERCADO' },
    { key: 'marker35', icon: redIcon, position: [-16.07991600036621, -47.987125396728516], content: 'ASSAI ATACADISTA-VALPARAISO' },
    { key: 'marker36', icon: redIcon, position: [-16.0163738, -48.0595732], content: 'DIA A DIA GAMA' },
    { key: 'marker37', icon: redIcon, position: [-16.03168296813965, -48.03387451171875], content: 'VIVENDAS ATACADO E VAREJO' },
    { key: 'marker38', icon: redIcon, position: [-15.834197, -48.1129964], content: 'DIA A DIA CEILANDIA' },
    { key: 'marker39', icon: redIcon, position: [-15.821739196777344, -48.10139846801758], content: 'ASSAI ATACADISTA-CEILANDIA' },
    { key: 'marker40', icon: redIcon, position: [-15.809402465820312, -48.089111328125], content: 'FORT ATACADISTA TAGUATINGA' },
  ];

  const MyPopupMarker = ({ content, position, icon }) => (
    <Marker position={position} icon={icon}>
      <Popup>{content}</Popup>
    </Marker>
  )

  const MyMarkersList = (makers) => {
    const items = markers.map(({ key, ...props }) => (
      <MyPopupMarker key={key} {...props} />
    ))
    return <>{items}</>
  }


  function Leaflet() {
    return (
      <Map center={[-15.779911, -47.8766529]} zoom={11}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MyMarkersList markers={markers} />
    </Map>
    );
  }
  
        

  export default Leaflet;
  