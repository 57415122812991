import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import tw from "date-fns/locale/pt-BR";

import "react-datepicker/dist/react-datepicker.css";
const DatePicker = props => {
    const [date, setDate] = useState(props.data);

    function handleDate(data) {
        setDate(data)
        props.handle(data, props.chave)
    }
    return (
        <ReactDatePicker
            className="form-control"
            selected={date}
            onChange={handleDate}
            locale={tw}
            dateFormat="dd/MM/yyyy"
            dateFormatCalendar="dd/MM/yyyy"
            isClearable

            placeholderText={setDate}

        />
    )
};

export default DatePicker;