//Encontrados/Não Encontrados

import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';
import Chart from "react-google-charts";




function AnaliseRuptura(props) {
    const [analiserup, setAnaliserup] = useState({});
  
    useEffect(() => {

        async function fetchMyAPI() {

            const AnaliseRupturaObj = {db_schema: 'pricing_bigbox'}
            const apiAnaliseRuptura = await fetch(props.apiUrl + "/gestao-precos-dashboard/analise-ruptura", {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                method: "POST",
                body: itensFiltro (AnaliseRupturaObj)
            });
            const AnaliseRupturaAPI = await apiAnaliseRuptura.json();

                var data = []
                data.push(['Loja Concorrente', 'Ruptura', 'Presente'])
                AnaliseRupturaAPI.data.forEach(loja => {
                    data.push([loja.nome_loja_concorrente, parseInt(loja.ruptura), parseInt(loja.presente) ])

                });
            setAnaliserup(data)

            await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    return (
        <Chart
            width={'100%'}
            height={'300px'}
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={analiserup}
            options={{
                chartArea: { width: '50%' },
                isStacked: true,
                hAxis: {
                minValue: 0,
                },
                vAxis: {
                },
            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    );
}






  export default  AnaliseRuptura;
  

  