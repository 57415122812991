import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});



function valuetext(value) {
  return `${value}°C`;
}
let timeout = null; 

export default function RangeSlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState([props.inicial, props.final]);
  const handleChange = (event, newValue) => {
     setValue(newValue);

    clearTimeout(timeout);

    timeout = setTimeout(function () {
        const sliderNumero = props.sliderNumero;
        props.handleFiltrosSlider(newValue[0], newValue[1], sliderNumero);
    }, 100);
  };
  
  if (!props.min ){
    return <></>;
  }
  
  var mark = [
    {
      value: parseInt(props.min),
      label: props.labelMin,
    },
    {
      value: parseInt(props.max),
      label: props.labelMax,
    }
  ]; 

  if (props.porcentagem) {
    mark = [
      {
        value: parseInt(props.min),
        label: props.labelMin,
      },
      {
        value: parseInt(props.max),
        label: props.labelMax,
      }
    ]; 
  }



  return (
    <div className={classes.root}>
      <Typography id="range-slider" gutterBottom>
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        defaultValue={5}
        min={Number(parseFloat(props.min).toFixed(1))}
        max={Number(parseFloat(props.max).toFixed(1))}
        aria-labelledby="range-slider"
        getAriaValueText={valuetext}
        marks={mark}
        valueLabelDisplay="on"

      />
    </div>
  );
}

