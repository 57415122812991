import React from "react";
import { sanitalize } from "../../shared/utils/sanitalize";

export function SetasNovoPrecoAtacadoModule(valor, row, atacado = false) {
  const preco_atual_varejo = sanitalize(row.preco_decisao ?? row.preco_min_max);
  const valor_alterado = sanitalize(valor);
  if (!atacado) {
    if (valor_alterado < preco_atual_varejo) {
      return (
        <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
      );
    }
    if (valor_alterado > preco_atual_varejo) {
      return (
        <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
      );
    }
    if (valor_alterado == preco_atual_varejo) {
      return (
        <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
      );
    }
  } else {
    if (valor_alterado > preco_atual_varejo) {
      return (
        <span className="glyphicon glyphicon-arrow-up seta_valor verde_"></span>
      );
    }
    if (valor_alterado < preco_atual_varejo) {
      return (
        <span className="glyphicon glyphicon-arrow-down seta_valor vermelho_font"></span>
      );
    }
    if (valor_alterado == preco_atual_varejo) {
      return (
        <span className="glyphicon glyphicon-ban-circle seta_valor amarelo_"></span>
      );
    }
  }
}
