import React from "react";

import TableCell from "@material-ui/core/TableCell";

import { mostraZero } from "../../shared/utils/mostraZero";
import { roundDown } from "../../shared/utils/roundDown";

export function rendergrupoArredondamentoBodyModule(row) {
  // if (grupoArredondamento.includes(user.dbschema)) {
  //   return (
  //     <>
  //       <TableCell className="page_a" component="th" scope="row">
  //         {row.tolerancia}
  //         {/*34 tolerancia*/}
  //       </TableCell>
  //       <TableCell className="page_a" component="th" scope="row">
  //         {mostraZero(roundDown(row.sugestao_arredondamento, 2))}
  //         {/*36 arredondamento*/}
  //       </TableCell>
  //     </>
  //   );
  // }
  return (
    <>
      <TableCell className="page_a" component="th" scope="row">
        {mostraZero(roundDown(row.sugestao_arredondamento, 2))}
        {/*36 arredondamento*/}
      </TableCell>
      <TableCell className="page_a" component="th" scope="row">
        {row.tolerancia}
        {/*34 tolerancia*/}
      </TableCell>
    </>
  );
}
