import itensFiltro from "../Helpers/ItensFiltro";

export async function precos(selectedfiltro, url, id) {
  const apiGestao = await fetch(url + "gestao/filtro/" + id, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });

  return await apiGestao.json();
}

export async function precosOrder(selectedfiltro, url, id) {
  const apiGestao = await fetch(url + "gestao/filtro/" + id, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });

  return await apiGestao.json();
}

export async function precosSlider(selectedfiltro, url, id) {
  const apiGestao = await fetch(url + "gestao/classificador/slider-filtro/" + id, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });

  return await apiGestao.json();
}

export async function precosSliderOrder(
  selectedfiltro,
  url,
  orderBy,
  orderDirection,
  id
) {
  const payload = {
    ...selectedfiltro,
    order_by: orderBy,
    order_direction: orderDirection,
  };

  const apiGestao = await fetch(
    url + "gestao/classificador/slider-filtro/order/" + id,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(payload),
    }
  );

  return await apiGestao.json();
}

export async function totalizadores(selectedfiltro, url, id) {
  const apiTotalizadores = await fetch(url + "gestao/totalizadores/" + id, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });
  const totalizadoresResultado = await apiTotalizadores.json();
  return totalizadoresResultado;
}
