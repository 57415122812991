import React from "react";
import { mostraZero } from "../../shared/utils/mostraZero";
import { sanitalize } from "../../shared/utils/sanitalize";
import { pesoFilho } from "../../shared/utils/pesoFilho";

export function renderInputAtacadoModule(
  row,
  index,
  bloqueados,
  exportar,
  editados,
  filhos,
  bindDosValores,
  proxCampo
) {
  const fator_atacado = Number(row.fator_atacado_simulador ?? "0");

  if (bloqueados === false) {
    if (
      (exportar === true && row.analizado == 1) ||
      editados === true ||
      bloqueados === true ||
      row.analizado === 2
    ) {
      return (
        <span className="input_filhos">
          {mostraZero(sanitalize(row.preco_decisao_atacado)) ||
            mostraZero(sanitalize(row.novo_preco_atacado)) ||
            0}{" "}
        </span>
      );
    }

    if (
      row.flag_pai_ou_filho === "FILHO" ||
      filhos === "FILHO" ||
      exportar === true ||
      bloqueados === true ||
      row.analizado == 2 ||
      fator_atacado <= 0
    ) {
      return (
        <span className="input_filhos">
          {" "}
          {mostraZero(sanitalize(row.preco_decisao_atacado)) ||
            mostraZero(sanitalize(row.novo_preco_atacado)) ||
            0}{" "}
        </span>
      );
    }
  }
  return (
    <>
      <input
        className={pesoFilho(row.flag_pai_ou_filho) + " type_valor"}
        type="number"
        onKeyPress={(e) => proxCampo(e, index, true)}
        onChange={(e) => bindDosValores(e, index, true)}
        placeholder={mostraZero(sanitalize(row.novo_preco_atacado)) || 0}
        // value={row.preco_decisao || ""}
        value={row.preco_decisao_atacado || ""}
        autoComplete="off"
        // onKeyDown={(evt) =>
        //   ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
        // }
        name="valor-input"
        required
        size="5"
        key={row.id + "rwx"}
      />
    </>
  );
}
