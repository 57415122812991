import React from "react";

const AuthPage = ({ children }) => (
  <>
    <div className="logo_box"></div>
    <div className="login">{children}</div>
    <div className="footer_landing">
      <div className="container-fluid">
        <div className="row">
          <div className="col s3">
            <p>
              <img
                className="pricemet"
                src="/image/pricemet.png"
                alt="some value"
              />
            </p>
          </div>
          <div className="col s3">
            <p className="pricemet_dados">
              Av. Marques de São Vicente 182 CJ. 81<br></br> Barra Funda - CEP.
              01139-000 - São Paulo-SP
            </p>
          </div>
          <div className="col s3">
            <p className="pricemet_dados">+ 55 (11) 3392-6305</p>
          </div>
          <div className="col s3">
            <p className="pricemet_dados">Um novo jeito de fazer Pricing.</p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default AuthPage;
