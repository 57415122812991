//Sensibilidade

import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';
import {Pie} from 'react-chartjs-2';




function SensibilidadePie(props) {
    const [sensib, setSensib] = useState({});
  
    useEffect(() => {

        async function fetchMyAPI() {

            const SensibilidadePieObj = {db_schema: 'pricing_bigbox'}
            const apiSensibilidadePie = await fetch(props.apiUrl + "/gestao-precos-dashboard/venda-sensibilidade", {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                method: "POST",
                body: itensFiltro (SensibilidadePieObj)
            });
            const SensibilidadePieAPI = await apiSensibilidadePie.json();


            const data = {
                labels: [
                    'Participação'
                ],
                datasets: [{
                    data: [300, 50, 100],
                    backgroundColor: [
                    '#de675b'
                    ],
                    hoverBackgroundColor: [
                    '#DC3912'
                    ],
                data2: [
                        (SensibilidadePieAPI.data[0].participacao*100).toFixed(1) 
                    ]
                }
            ]
            };

            setSensib(data);

            await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    return (
        <Pie
            data={sensib}
            width={630}
            height={240}
            options={{
                maintainAspectRatio: false
            }}
        />
    );
}






  export default  SensibilidadePie;
  

  