export default function itensFiltro (obj){
    var str = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key]) {

                str.push(encodeURIComponent(key) + "='" + encodeURIComponent(obj[key])+ "'")                  
            }else {

                str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))                  
            }
        }
    }
    return str.join("&");
}