//Sensibilidade

import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';
import Chart from "react-google-charts";




function SensibilidadeTabela(props) {
    const [sensibTabela, setSensibTabela] = useState({});
  
    useEffect(() => {

        async function fetchMyAPI() {

            const SensibilidadeTabelaObj = {db_schema: 'pricing_bigbox'}
            const apiSensibilidadeTabela = await fetch(props.apiUrl + "/gestao-precos-dashboard/venda-sensibilidade", {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                method: "POST",
                body: itensFiltro (SensibilidadeTabelaObj)
            });
            const SensibilidadeTabelaAPI = await apiSensibilidadeTabela.json();

                var data = []
                data.push([{ type: 'string', label: 'Sensibilidade' }, { type: 'number', label: 'Quantidade de Itens'}, { type: 'number', label: 'Participação de Vendas'}])
                SensibilidadeTabelaAPI.data.forEach(loja => {
                    data.push([loja.sensibilidade, parseInt(loja.quantidade / 1000 ), parseFloat( (loja.participacao*100).toFixed (1)) ])

                });
            setSensibTabela(data)

            await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    return (
        <Chart
            width={'100%'}
            height={'300px'}
            chartType="Table"
            loader={<div>Loading Chart</div>}
            data={sensibTabela}
            options={{
                showRowNumber: true,
            }}
            rootProps={{ 'data-testid': '1' }}
        />
    );
}






  export default  SensibilidadeTabela;
  

  