import itensFiltro from "../Helpers/ItensFiltro";

//const apiUrl = "https://bigboxapi.prixsia.com/api/v2";
const apiUrl = process.env.REACT_APP_API_SWIFT+"/api/v2";

export async function tabela(selectedfiltro) {
  const apiSwift = await fetch(`${apiUrl}/gestao/filtro`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });
  const swiftResultado = await apiSwift.json();
  return swiftResultado;
}

export async function filtroDependentes(selectedfiltro) {
  const apiSwiftFiltro = await fetch(`${apiUrl}/gestao/filtro-dependentes`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });
  const swiftResultadoFiltro = await apiSwiftFiltro.json();
  return swiftResultadoFiltro;
}

export async function totalizadores(selectedfiltro) {
  const apiSwfitTotalizadores = await fetch(
    `${apiUrl}/swift/gestao/totalizadores`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(selectedfiltro),
    }
  );
  const swiftResultadoTotalizadores = await apiSwfitTotalizadores.json();
  return swiftResultadoTotalizadores;
}

export function getVendaEstimada(valor, row) {
  const vendaEstimada = {
    cluster: row.cluster_simulador,
    codigo_pai: row.codigo_pai,
    fator: valor,
    db_schema: "swift",
  };
  return vendaEstimada;
}

export async function updateVendaEstimada(valor, row) {
  const apiVendaEstimada = await fetch(
    `${apiUrl}/gestao/update-venda-estimada`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(getVendaEstimada(valor, row)),
    }
  );
  const swiftVendaEstimada = await apiVendaEstimada.json();
  return swiftVendaEstimada;
}

export function getUpdatenovoPreco(valor, row) {
  var analizado = "1";
  if (valor === row.preco_default) {
    analizado = "0";
  }
  const novoPreco = {
    cluster: row.cluster_simulador,
    codigo_pai: row.codigo_pai,
    preco_decisao: valor,
    analizado: analizado,
    exportado: "0",
    uid: "39",
    db_schema: "swift",
  };
  return novoPreco;
}

export async function getUpdateToggle(row, valor) {
  const novoPreco = {
    cluster: row.cluster_simulador,
    codigo_pai: row.codigo_pai,
    preco_decisao: row.preco_min_max,
    analizado: `${valor}`,
    exportado: `0`,
    uid: "39",
    db_schema: "swift",
  };
  const apiNovoPreco = await fetch(`${apiUrl}/swift/gestao/update`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(novoPreco),
  });
}

export async function updatenovoPreco(valor, row) {
  const apiNovoPreco = await fetch(`${apiUrl}/gestao/update`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(getUpdatenovoPreco(valor, row)),
  });
  const swiftNovoPreco = await apiNovoPreco.json();
  return swiftNovoPreco;
}

export async function search(selectedfiltro, inputText) {
  var clusterSlider = selectedfiltro.cluster;
  if (selectedfiltro.cluster === null) {
    clusterSlider = "";
  }
  const apiSearch = await fetch(
    `${apiUrl}/swift/gestao/search/${inputText.toUpperCase()}/${clusterSlider}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  const searchItem = await apiSearch.json();
  return searchItem;
}

export async function apiListaItens() {
  const apiListaItensAPI = await fetch(
    `${apiUrl}/swift/gestao/update/lista-itens-editados`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro({ uid: "39" }),
    }
  );
  const swiftListaItens = await apiListaItensAPI.json();
  return swiftListaItens;
}

export async function apiexportarItens(data) {
  const apiexportarItens = await fetch(`${apiUrl}/swift/gestao/export`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro({ uid: "39", data: data }),
  });
  const exportarItens = await apiexportarItens.json();
  return exportarItens;
}

export async function apiItensExportados() {
  const apiItensExportados = await fetch(
    `${apiUrl}/swift/gestao/update/lista-itens-exportados`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro(),
    }
  );
  const itensExportados = await apiItensExportados.json();
  return itensExportados;
}

export async function resetarExportados() {
  const resetarExportados = await fetch(
    `${apiUrl}/swift/gestao/update/reset-editados`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      method: "POST",
      body: itensFiltro({ uid: "39" }),
    }
  );
  const itensResetados = await resetarExportados.json();
  return itensResetados;
}

export async function separator() {
  const dbschema = "swift";
  if (dbschema == "swift") {
    return ";";
  }
}

export async function tabelaDownload() {
  const tabelaDownload = await fetch(`${apiUrl}/swift/gestao/file-download`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(),
  });
  const baixarTabela = await tabelaDownload.json();
  return baixarTabela;
}

export async function paiseFilhos(selectedfiltro) {
  const paiseFilhos = await fetch(`${apiUrl}/gestao/filtro/proporcionais`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });
  const filhosProporcionais = await paiseFilhos.json();
  return filhosProporcionais;
}

export async function checkboxes(selectedfiltro, valor) {
  selectedfiltro.uid = "39";
  selectedfiltro.analizado = "0";
  if (valor) {
    selectedfiltro.analizado = "1";
  }

  const checkboxes = await fetch(`${apiUrl}/swift/gestao/update/checkboxes`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    method: "POST",
    body: itensFiltro(selectedfiltro),
  });
  const checkboxesMultiplo = await checkboxes.json();
  return checkboxesMultiplo;
}

export function getFiltrosDefault() {
  const filtrosDefault = {
    grupo: null,
    fornecedor: null,
    produto: null,
    bandeira: null,
    // nome_bandeira:null,
    sensibilidade: null,
    papel_categoria: null,
    sub_grupo: null,
    // subgrupo:null,
    cluster: null,
    departamento: null,
    escala: null,
    secao: null,
    registros: "150",
    pagina: "1",
    db_schema: "swift",
    tipo: "pais",
    tem_pesquisa:null,
    userFilters:
      '{"bandeira":null;"cluster":null;"departamento":null;"secao":null;"grupo":null;"sub_grupo":null;"produto":null;"sensibilidade":null;"papel_categoria":null;"escala":null;"fornecedor":null;"tem_pesquisa":null}',
  };
  return filtrosDefault;
}
