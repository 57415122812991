import React, {useEffect} from "react";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

import GlobalState from "../../contexts/GlobalState";
import { useContext } from "react";
import { useState } from "react";


export default function AgentamentosV2() {
  const state = useContext(GlobalState)[0];
  const [isValid, setIsValid] = useState(false);
  useEffect(()=>{
   state.value?.user.id === 45 && setIsValid(true)
  }, [state.value?.user])

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 10rem)",
        marginTop: "-1.5rem",
        position: "relative",
      }}
    >
      <div
        style={{
          backgroundColor: "#253b6d",
          width: "100%",
          height: "50%",
        }}
      />
      <div style={{ width: "100%", height: "50%" }} />
      <div
        style={{
          position: "absolute",
          top: "2rem",
          left: "2rem",
          right: "2rem",
          bottom: "2rem",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <LeftPanel />
          <RightPanel isValid={isValid} />
        </div>
      </div>
    </div>
  );
}
