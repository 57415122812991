import React, { useState, useEffect } from "react";

import Leaflet from "./Leaflet";
import Totalizadores from "./Graficos/Totalizadores";
import CompXConc from "./Graficos/CompXConc";
import SensibilidadePie from "./Graficos/SensibilidadePie";
import SensibilidadeTabela from "./Graficos/SensibilidadeTabela";
import AnaliseRuptura from "./Graficos/AnaliseRuptura";
import AnaliseQuantitativo from "./Graficos/AnaliseQuantitativo";
import VendaMargemTipo from "./Graficos/VendaMargemTipo";
import { useAuth } from "../contexts/AuthContext";
function Dashboard() {
  const { user: usuario } = useAuth();
  const [user, setUser] = useState();
  //const [apiUrl, setApiUrl] = useState("https://bigboxapi.prixsia.com/api/v1/");
  const [apiUrl, setApiUrl] = useState(
    process.env.REACT_APP_API_BIGBOX + "/api/v1/"
  );

  useEffect(() => {
    async function fetchMyAPI() {
      const userApi = await fetch(
        process.env.REACT_APP_API_BIGBOX + usuario.email
        //"https://bigboxapi.prixsia.com/api/users/config/" +
      );

      const userObj = await userApi.json();

      setUser(userObj.data);
      if (userObj.data) {
        setApiUrl(userObj.data.system.url);
      }
    }

    fetchMyAPI();
  }, [usuario.email]);

  if (!user) {
    return "";
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="titulo_inicial">
            <a href="/tabela">
              <p className="data_topo titulo_inicial2">Gestão de Preço</p>
            </a>
            <p className="titulo_inicial2">Painel Executivo de Pricing</p>
            <hr />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <p className="p_flexdash">Resultados Mês Atual Acumulado</p>
          <Totalizadores user={user} apiUrl={apiUrl} />
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-6 chart">
            <span className="titulo_chart">Vendas e Margem por Tipo</span>
            <div className="table_dash">
              <VendaMargemTipo user={user} apiUrl={apiUrl} />

              {/* <table className="table table--tickets">
                                <thead>
                                    <tr>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Venda Bruta R$</th>
                                    <th scope="col">Part %</th>
                                    <th scope="col">Margem R$</th>
                                    <th scope="col">Margem %</th>
                                    <th scope="col">Contrib %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">#12345</th>
                                    <td className='table_dash_status'>Resolvido</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">#12346</th>
                                    <td className='table_dash_status'>Resolvido</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">#12347</th>
                                    <td className='table_dash_status'>Resolvido</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">#12349</th>
                                    <td className='table_dash_status'>Em aberto</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    <td>lorem ipsum</td>
                                    </tr>
                                </tbody>
                            </table> */}
            </div>
          </div>
          <div className="col-6 chart">
            <span className="titulo_chart">
              Histórico de Vendas e Margens %
            </span>
            <div className="areachart box--com--sombra">
              {/*COMBO - linhas verticais e riscos*/}
              {/* <Chart
                                width={'500px'}
                                height={'250px'}
                                chartType="ComboChart"
                                loader={<div>Loading Chart</div>}
                                data={[
                                    [
                                    'Month',
                                    'Bolivia',
                                    'Ecuador',
                                    'Madagascar',
                                    'Papua New Guinea',
                                    'Rwanda',
                                    'Average',
                                    ],
                                    ['2004/05', 165, 938, 522, 998, 450, 614.6],
                                    ['2005/06', 135, 1120, 599, 1268, 288, 682],
                                    ['2006/07', 157, 1167, 587, 807, 397, 623],
                                    ['2007/08', 139, 1110, 615, 968, 215, 609.4],
                                    ['2008/09', 136, 691, 629, 1026, 366, 569.6],
                                ]}
                                options={{
                                    title: '',
                                    vAxis: { title: 'Cups' },
                                    hAxis: { title: 'Mês/Ano' },
                                    seriesType: 'bars',
                                    series: { 5: { type: 'line' } },
                                }}
                                rootProps={{ 'data-testid': '1' }}
                                /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 chart">
            <span className="titulo_chart">
              Índice de Competitividade vs Concorrentes Médio
            </span>
            <div className="areachart box--com--sombra">
              <CompXConc user={user} apiUrl={apiUrl} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 chart">
            <span className="titulo_chart">
              Índice Quantitativo - Preço Varejo
            </span>
            <div className="areachart box--com--sombra">
              <AnaliseQuantitativo user={user} apiUrl={apiUrl} />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="col-12 chart">
          <span className="titulo_chart">Encontrados/Não Encontrados</span>
          <span className="titulo_chart total_geral">Total Geral</span>

          <div className="row box--com--sombra">
            <div className="col-md-12 col-lg-8 no--padding chart">
              <AnaliseRuptura user={user} apiUrl={apiUrl} />
              <div></div>
            </div>

            <div className="col-md-12 col-lg-4 no--padding chart">
              <div className="areachart centralizar--filho ">
                {/* <Chart
                        width={'300px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Task', 'Hours per Day'],
                            ['Work', 11],
                            ['Eat', 2],
                            ['Commute', 2],
                            ['Watch TV', 2],
                            ['Sleep', 7],
                        ]}
                        options={{
                            title: '',
                            // Just add this option
                            pieHole: 0.4,
                        }}
                        rootProps={{ 'data-testid': '3' }}
                    />                                     */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 chart col-xl-12">
            <span className="titulo_chart">Concorrentes</span>
            <div className="map">
              <Leaflet />
            </div>
          </div>
        </div>

        <div className="col-12 chart">
          <span className="titulo_chart">Sensibilidade</span>
          <div className="row box--com--sombra">
            <div className="col-md-12 col-lg-8 chart">
              <div>
                <SensibilidadeTabela user={user} apiUrl={apiUrl} />
              </div>
            </div>

            <div className="col-md-12 col-lg-4 chart">
              <div className="areachart centralizar--filho ">
                <SensibilidadePie user={user} apiUrl={apiUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
