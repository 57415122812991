import React from "react";
import Table from "./Table";
import Header from "./Header";
import Dashboard from "./Dashboard";
import ResetPassword from "./AuthPages/ResetPassword";
import Login from "./AuthPages/Login";
import Agendamentos from "./AgendamentosV2";
import Navbar from "./Navbar";
import Swift from "./Swift";
import Leaflet from "./Leaflet";
import Account from "./Account";
import Totalizadores from "./Graficos/Totalizadores";
import CompXConc from "./Graficos/CompXConc";
import SensibilidadePie from "./Graficos/SensibilidadePie";
import SensibilidadeTabela from "./Graficos/SensibilidadeTabela";
import AnaliseRuptura from "./Graficos/AnaliseRuptura";
import AnaliseQuantitativo from "./Graficos/AnaliseRuptura";
import VendaMargemTipo from "./Graficos/VendaMargemTipo";
// import CSV from './CSV';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Varejo from "./Varejo";
import Parametros from "./Parametros";
import { useAuth } from "../contexts/AuthContext";
require("dotenv").config();

function Controller() {
  const { userLogged } = useAuth();
  const user = userLogged();
  return (
    <Router>
      {user ? (
        <Switch>
          <Route exact path="/">
            <Redirect to="/agendamentos" />
          </Route>
          <Route path="/tabela">
            <Header />
            <div className="bgBlue"></div>
            <div id="wrap--content">
              <Table />
            </div>
            <Navbar />
          </Route>
          <Route path="/agendamentos">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Agendamentos />
            </div>
          </Route>
          <Route path="/varejo">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Varejo />
            </div>
          </Route>
          <Route path="/parametros">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Parametros />
            </div>
          </Route>
          <Route path="/swift">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Swift />
            </div>
          </Route>
          <Route path="/mapa">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Leaflet />
            </div>
          </Route>
          <Route path="/totalizadores">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Totalizadores />
            </div>
          </Route>
          <Route path="/compXConc">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <CompXConc />
            </div>
          </Route>
          <Route path="/sensibilidadePie">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <SensibilidadePie />
            </div>
          </Route>
          <Route path="/sensibilidadeTabela">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <SensibilidadeTabela />
            </div>
          </Route>
          <Route path="/analiseRuptura">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <AnaliseRuptura />
            </div>
          </Route>
          <Route path="/analiseQuantitativo">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <AnaliseQuantitativo />
            </div>
          </Route>
          <Route path="/vendaMargemTipo">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <VendaMargemTipo />
            </div>
          </Route>
          <Route path="/dashboard">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Dashboard />
            </div>
          </Route>
          {/* <Route path="/csv">
          <Header/>
          <Navbar/>
          <div id="wrap--content"> 
            <CSV/>
          </div>
        </Route> */}
          <Route path="/account">
            <Header />
            <Navbar />
            <div id="wrap--content">
              <Account />
            </div>
          </Route>
          <Route path="*">
            <Redirect to="/agendamentos" />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="*">
            <Redirect to={"/login"} />
          </Route>
        </Switch>
      )}
    </Router>
  );
}
export default Controller;
