import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';




function Totalizadores(props) {
    const [totalizadores, setTotalizadores] = useState([]);
      
    useEffect(() => {        

        async function fetchMyAPI() {

        const totalizadoresObj = {db_schema: 'pricing_bigbox'}
        const apiTotalizadoresDash = await fetch(props.apiUrl + "gestao-precos-dashboard/totalizadores-dash", {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            method: "POST",
            body: itensFiltro (totalizadoresObj)
        });
        const totalizadoresAPI = await apiTotalizadoresDash.json();
        setTotalizadores (totalizadoresAPI.data[0]);

        await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    function mostraZeroDec (decimals) {
        if (!decimals){
            return;
        }
        return decimals.toFixed(1);
    }
  
    return (
        <div className="container-fluid box_tit">
            <div className='row'>
                <div className='col item_dash'>
                    <p className='menu_dash tomato'>Venda Realizada<br></br></p>
                    <p className='tot_dash'><span className='total_dash'>{parseFloat(totalizadores.valor/1000000).toFixed(1)}M</span></p>
                </div>
                <div className='col item_dash'>
                    <p className='menu_dash azul_neutro'>Participação Promoções<br></br></p>
                    <p className='tot_dash'><span className='total_dash'>{parseFloat(totalizadores.participacoes_promocoes_porcentagem*100).toLocaleString(undefined, {maximumFractionDigits: 1})}%</span></p>
                </div>
                <div className='col item_dash'>
                    <p className='menu_dash laranja'>Margem Realizada<br></br></p>
                    <p className='tot_dash'><span className='total_dash'>{mostraZeroDec(parseFloat(totalizadores.margem_realizada*100))}%</span></p>
                </div>
                <div className='col item_dash'>
                    <p className='menu_dash verde'>Índice Preços Varejo<br></br></p>
                    <p className='tot_dash'><span className='total_dash'>{parseFloat(totalizadores.indice_competitividade_novo_ponderado_atacado).toLocaleString(undefined, {maximumFractionDigits: 1})}%</span></p>
                </div>
                <div className='col item_dash'>
                    <p className='menu_dash verde'>Índice Preços Atacado<br></br></p>
                    <p className='tot_dash '><span className='total_dash'>{parseFloat(totalizadores.indice_competitividade_novo_ponderado_varejo).toLocaleString(undefined, {maximumFractionDigits: 1})}%</span></p>

                </div> 
            </div>
        </div>
    );
  }






  export default Totalizadores;
  