import * as React from "react";
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  Typography,
  TextField,
} from "@material-ui/core";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function SelectAllTransferList(props) {
  const fields = [
    {
      id: "Cluster crescente",
      display: "cluster_simulador asc",
    },
    {
      id: "Cluster decrescente",
      display: "cluster_simulador desc",
    },
    {
      id: "Código principal proporcional crescente",
      display: "cod_pai_proporcao asc",
    },
    {
      id: "Código principal proporcional decrescente",
      display: "cod_pai_proporcao desc",
    },
    {
      id: "Código pai crescente",
      display: "codigo_pai asc",
    },
    {
      id: "Código pai decrescente",
      display: "codigo_pai desc",
    },
    {
      id: "Código sku crescente",
      display: "codigo_filhos asc",
    },
    {
      id: "Código sku decrescente",
      display: "codigo_filhos desc",
    },
    {
      id: "Descricao produto crescente",
      display: "descricao_produto asc",
    },
    {
      id: "Descricao produto decrescente",
      display: "descricao_produto desc",
    },
    {
      id: "Status produto crescente",
      display: "status_produto asc",
    },
    {
      id: "Status produto decrescente",
      display: "status_produto desc",
    },
    {
      id: "Preco vigente varejo crescente",
      display: "preco_vigente_varejo asc",
    },
    {
      id: "Preco vigente varejo decrescente",
      display: "preco_vigente_varejo desc",
    },
    {
      id: "Novo preco regular varejo crescente",
      display: "preco_min_max asc",
    },
    {
      id: "Novo preco regular varejo decrescente",
      display: "preco_min_max desc",
    },
    {
      id: "Custo crescente",
      display: "custo_do_produto asc",
    },
    {
      id: "Custo decrescente",
      display: "custo_do_produto desc",
    },
    {
      id: "Impostos crescente",
      display: "impostos_simulador asc",
    },
    {
      id: "Impostos decrescente",
      display: "impostos_simulador desc",
    },
    {
      id: "Margem objetiva crescente",
      display: "margem_objetiva_produto asc",
    },
    {
      id: "Margem objetiva decrescente",
      display: "margem_objetiva_produto desc",
    },
    {
      id: "Margem atual crescente",
      display: "margem_atual_regular asc",
    },
    {
      id: "Margem atual decrescente",
      display: "margem_atual_regular desc",
    },
    {
      id: "Margem nova crescente",
      display: "margem_nova asc",
    },
    {
      id: "Margem nova decrescente",
      display: "margem_nova desc",
    },
    {
      id: "Sensibilidade crescente",
      display: "sensibilidade_fornecedor asc",
    },
    {
      id: "Sensibilidade decrescente",
      display: "sensibilidade_fornecedor desc",
    },
    {
      id: "Variacao novo preco crescente",
      display: "var_novo_preco asc",
    },
    {
      id: "Variacao novo preco decrescente",
      display: "var_novo_preco desc",
    },
    {
      id: "Preco concorrente pricipal crescente",
      display: "preco_primario asc",
    },
    {
      id: "Preco concorrente pricipal decrescente",
      display: "preco_primario desc",
    },
    {
      id: "Preco concorrente secundario crescente",
      display: "preco_secundario asc",
    },
    {
      id: "Preco concorrente secundario decrescente",
      display: "preco_secundario desc",
    },
    {
      id: "Preco concorrente terciario crescente",
      display: "preco_terciario asc",
    },
    {
      id: "Preco concorrente terciario decrescente",
      display: "preco_terciario desc",
    },
    {
      id: "Preco concorrente quartenario crescente",
      display: "preco_quaternario asc",
    },
    {
      id: "Preco concorrente quartenario decrescente",
      display: "preco_quaternario desc",
    },
    {
      id: "Preco concorrente quinario crescente",
      display: "preco_quinto asc",
    },
    {
      id: "Preco concorrente quinario decrescente",
      display: "preco_quinto desc",
    },
    {
      id: "Preco concorrente senario crescente",
      display: "preco_sexto asc",
    },
    {
      id: "Preco concorrente senario decrescente",
      display: "preco_sexto desc",
    },
    {
      id: "Preco concorrente setenario crescente",
      display: "preco_setimo asc",
    },
    {
      id: "Preco concorrente setenario decrescente",
      display: "preco_setimo desc",
    },
    {
      id: "Preco concorrente octionario Crescente",
      display: "preco_octonario asc",
    },
    {
      id: "Preco concorrente octionario decrescente",
      display: "preco_octonario desc",
    },
    {
      id: "Preco concorrente estudo crescente",
      display: "preco_concorrente_estudo asc",
    },
    {
      id: "Preco concorrente estudo decrescente",
      display: "preco_concorrente_estudo desc",
    },
    {
      id: "Preco medio concorrente crescente",
      display: "preco_medio_conc asc",
    },
    {
      id: "Preco medio concorrente decrescente",
      display: "preco_medio_conc desc",
    },
    {
      id: "Indice meta drescente",
      display: "indice_meta asc",
    },
    {
      id: "Indice meta decrescente",
      display: "indice_meta desc",
    },
    {
      id: "Indice regular atual crescente",
      display: "indice_regular_atual asc",
    },
    {
      id: "Indice regular atual decrescente",
      display: "indice_regular_atual desc",
    },
    {
      id: "Indice novo preco regular crescente",
      display: "indice_novo_preco_regular asc",
    },
    {
      id: "Indice novo preco regular decrescente",
      display: "indice_novo_preco_regular desc",
    },
    {
      id: "Departamento crescente",
      display: "nome_departamento asc",
    },
    {
      id: "Departamento decrescente",
      display: "nome_departamento desc",
    },
    {
      id: "Secao crescente",
      display: "nome_secao asc",
    },
    {
      id: "Secao decrescente",
      display: "nome_secao desc",
    },
    {
      id: "Grupo crescente",
      display: "nome_grupo asc",
    },
    {
      id: "Grupo decrescente",
      display: "nome_grupo desc",
    },
    {
      id: "Subgrupo crescente",
      display: "nome_subgrupo asc",
    },
    {
      id: "Subgrupo decrescente",
      display: "nome_subgrupo desc",
    },
    {
      id: "Papel categoria crescente",
      display: "papel_categoria_simulador asc",
    },
    {
      id: "Papel categoria decrescente",
      display: "papel_categoria_simulador desc",
    },
    {
      id: "Quantidade vendas regular atual crescente",
      display: "vendas asc",
    },
    {
      id: "Quantidade vendas regular atual decrescente",
      display: "vendas desc",
    },
    {
      id: "Quantidade vendas regular estimada crescente",
      display: "qtde_vendas_estimada asc",
    },
    {
      id: "Quantidade vendas regular estimada decrescente",
      display: "qtde_vendas_estimada desc",
    },
    {
      id: "Diferenca unitario crescente",
      display: "diferenca_preco_unitario asc",
    },
    {
      id: "Diferenca unitario decrescente",
      display: "diferenca_preco_unitario desc",
    },
    {
      id: "Diferenca total crescente",
      display: "diferenca_total asc",
    },
    {
      id: "Diferenca total decrescente",
      display: "diferenca_total desc",
    },
    {
      id: "Preco inicial crescente",
      display: "preco_atual_varejo asc",
    },
    {
      id: "Preco inicial decrescente",
      display: "preco_atual_varejo desc",
    },
    {
      id: "Politica pricing/mix crescente",
      display: "politica_pricing asc",
    },
    {
      id: "Politica pricing/mix decrescente",
      display: "politica_pricing desc",
    },
    {
      id: "Margem minima maxima crescente",
      display: "margem_min_max asc",
    },
    {
      id: "Margem minima maxima decrescente",
      display: "margem_min_max desc",
    },
    {
      id: "Preco escala crescente",
      display: "sugestao_escala asc",
    },
    {
      id: "Preco escala decrescente",
      display: "sugestao_escala desc",
    },
    {
      id: "Arredondamento crescente",
      display: "arredondamento asc",
    },
    {
      id: "Arredondamento decrescente",
      display: "arredondamento desc",
    },
    {
      id: "Preco toleranca crescente",
      display: "preco_toleranca asc",
    },
    {
      id: "Preco toleranca decrescente",
      display: "preco_toleranca desc",
    },
    {
      id: "Preco minimo maximo crescente",
      display: "preco_min_max asc",
    },
    {
      id: "Preco minimo maximo decrescente",
      display: "preco_min_max desc",
    },
    {
      id: "Preco proporcional crescente",
      display: "preco_proporcional asc",
    },
    {
      id: "Preco proporcional decrescente",
      display: "preco_proporcional desc",
    },
    {
      id: "Preco final crescente",
      display: "sugestao_final asc",
    },
    {
      id: "Preco final decrescente",
      display: "sugestao_final desc",
    },
    {
      id: "Fator propor min crescente",
      display: "ftp_fator asc",
    },
    {
      id: "Fator propor min decrescente",
      display: "ftp_fator desc",
    },
    {
      id: "Fator propor max crescente",
      display: "ftp_fator_max asc",
    },
    {
      id: "Fator propor max decrescente",
      display: "ftp_fator_max desc",
    },
    {
      id: "Nome escala crescente",
      display: "nome_escala asc",
    },
    {
      id: "Nome escala decrescente",
      display: "nome_escala desc",
    },
    {
      id: "Posicao escala crescente",
      display: "posicao_escala asc",
    },
    {
      id: "Posicao escala decrescente",
      display: "posicao_escala desc",
    },
    {
      id: "Porcentagem escala crescente",
      display: "porcentagem_escala asc",
    },
    {
      id: "Porcentagem escala decrescente",
      display: "porcentagem_escala desc",
    },
  ];
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(fields);
  const [right, setRight] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [change, setChange] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const saveOrUpdate = async () => {
    // const command = actualJql.replace(/\[/g, "").replace(/\]/g, "");

    const items = [];
    right.map((item) => {
      return items.push(item.display);
    });
    const command = items && `order by ${items.join(", ")}`;

    await fetch(`${props.user.apiurl}jql/${props.user.dbschema}`, {
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        order: command,
        user: props.user.user.id,
      }),
    });
    setChange(true);
    document.querySelector("#modal-order-close").click();

    props.filtroBandeira();
    // window.location.reload();
  };

  const removeFilter = async () => {
    await fetch(
      `${props.user.apiurl}jql/delete/${props.user.dbschema}/${props.user.user.id}`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "GET",
      }
    );
    setChange(false);
    document.querySelector("#modal-order-close").click();

    props.filtroBandeira();
  };

  const customList = (title, items, searchBox = false) => (
    <Card
      style={{
        height: "50rem",
        width: "30rem",
        overflowY: "scroll",
        marginBottom: "2rem",
      }}
    >
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        titleTypographyProps={{ variant: "h6" }}
        subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
        subheaderTypographyProps={{ variant: "h6" }}
      />
      {searchBox ? (
        <TextField
          id="standard-basic"
          label="Selecionar Filtro"
          variant="standard"
          style={{ marginBottom: "1rem", width: "90%", marginLeft: "0.5rem" }}
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
        />
      ) : (
        ""
      )}
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items
          .filter(({ id }) =>
            search !== "" ? id.toLowerCase().includes(search) : items
          )
          .map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;
            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={
                    <Typography variant="body2" style={{ fontSize: "1.3rem" }}>
                      {value.id}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </Card>
  );

  return (
    <>
      {change && (
        <Button
          variant="contained"
          color="primary"
          style={{
            fontSize: "1.5rem",
            height: "4rem",
            width: "18rem",
            marginBottom: "2rem",
            marginLeft: "2rem",
          }}
          onClick={() => removeFilter()}
        >
          Limpar
        </Button>
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{customList("Escolhas", left, true)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="large"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="large"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList("Escolhidos", right)}</Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        style={{ fontSize: "1.5rem", height: "5rem" }}
        onClick={() => saveOrUpdate()}
        disabled={right.length > 0 ? false : true}
      >
        Ordenar
      </Button>
    </>
  );
}
