import LoadingOverlay from 'react-loading-overlay';
import React, { useEffect, useState } from 'react';
import { Auth, API, graphqlOperation } from "aws-amplify";
import Toastify from 'toastify-js'
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { useForm } from 'react-hook-form';
import { AmplifyS3ImagePicker } from '@aws-amplify/ui-react';
// import { AmplifyS3Image } from '@aws-amplify/ui-react';





export default function Account(props) {
    
    const { register, handleSubmit, reset } = useForm();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ user, setUser ] = useState();
    const [username, setUsername] = useState('');

    useEffect(() => {

        async function createUser (){
            
            const user = await API.graphql(
            graphqlOperation(
                queries.listUsers, 
                {     
                    filter: {
                        owner: {
                            eq: Auth.user.username
                        }
                    } 
                },
            1)
            )    
            if(!user.data.listUsers.items[0]){
                let user = {
                    cpf:"",
                    rg:"",
                    age:"",
                    neighborhood:"",
                    city:"",
                    uf:""
                }
                await API.graphql(graphqlOperation(mutations.createUser, {input: {...user}}));
            }
            
        }

        async function fetchMyAPI() {
          
          const usuario= await Auth.currentAuthenticatedUser()
          setUsername(usuario) 
          
            
        }  
        createUser();
        fetchMyAPI();
      }, []);

    useEffect(() => {
        async function fetchData() {
            const user = await API.graphql(
                graphqlOperation(
                    queries.listUsers, 
                    {     
                        filter: {
                            owner: {
                                eq: Auth.user.username
                            }
                        } 
                    },
                1)
                )    
                console.log("fetchData -> user", user.data.listUsers.items[0])
            
            setUser(user.data.listUsers.items[0]);       
            console.log(user.data.listUsers.items[0]);     
            reset({ ...user.data.listUsers.items[0] }) 
            
        }
        fetchData();
        setIsLoading(false);
        
    }, [reset]);

    const onSubmit = async function(data) {
        const id = user.id;
        delete user.createdAt;
        delete user.updatedAt;
        const userUp = {...user, ...data, id }
        delete userUp.user;    
        setIsLoading(true);  
        await API.graphql(graphqlOperation(mutations.updateUser, {  input: userUp, filter: {
            owner: {
                eq: Auth.user.username
            }
        } }, 1))





        setIsLoading(false);

        Toastify({
            text: "Informações salvas com sucesso",
            duration: 3000, 
            newWindow: true,
            close: true,
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function(){          // Callback after click
                window.location.href="/agendamentos";
            }
          }).showToast();
    };

  
    
    return (
        <div>
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Carregando...'
                >
            </LoadingOverlay>
        
            <div className='container'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input className="form-control" name="name" id="name" ref={register} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="telephone">Telefone</label>
                        <input className="form-control" name="telephone" id="telephone" ref={register} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="cpf">CPF</label>
                        <input className="form-control" name="cpf" id="cpf" ref={register} />
                    </div>

                    {/* <AmplifyS3Image imgKey={ username.username + ".png" } /> */}
                    <AmplifyS3ImagePicker fileToKey={ username.username + ".png" } /> 

                    
                    <div className="form-group">
                        <input type="submit" value="Salvar" />
                    </div>
                </form>
            </div>
           


        </div>
    )

}