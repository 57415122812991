import React from "react";
export function renderXModule(search, limparSearchDoX) {
  if (search === "") {
    return;
  }
  return (
    <span className="search_x" onClick={limparSearchDoX}>
      X
    </span>
  );
}
