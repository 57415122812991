import React from "react";

export function renderFoto(foto) {
  if (!foto && foto === "") {
    return;
  }
  return (
    <>
      <p>
        <span className="glyphicon glyphicon-camera fotos"></span>
      </p>
      <span className="tooltiptext">
        <img className="range_promo" alt="some value" src={foto} />
      </span>
    </>
  );
}
