import moment from "moment";

export function renderDataAtualizacaoModule(rows) {
  if (!rows[0]) {
    return;
  }

  const formattedDate = moment(rows[0].dt_insert)
    .subtract(6, "hours")
    .format("DD/MM/YYYY, hh:mm:ss");
  return formattedDate;
}
