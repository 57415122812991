import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
//   noLabel: {
//     marginTop: theme.spacing(3),
//   },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
      setPersonName(event.target.value);
    };

    function handleClose() {
        props.handle(props.filter, personName)
    }

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel className='opcao' htmlFor="grouped-native-select">{props.descripton}</InputLabel>        
                <Select
                    labelId="demo-mutiple-name-label"
                    id={`${props.descripton}-select`}
                    multiple
                    value={personName}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<Input />}
                    MenuProps={MenuProps}
                >
                    {props.data.map((name) => (
                    <MenuItem key={`${name}-multselect`} value={name} style={getStyles(name, personName, theme)}>
                        {name}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}