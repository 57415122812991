import React from "react";

import TableCell from "@material-ui/core/TableCell";

export function rendergrupoArredondamentoHeaderModule(ordenar) {
  // if (grupoArredondamento.includes(user.dbschema)) {
  //   return (
  //     <>
  //       <TableCell
  //         className="titulo_tabela"
  //         scope="col"
  //         onClick={(e) => ordenar("tolerancia")}
  //       >
  //         Preço Tolerância
  //       </TableCell>
  //       <TableCell
  //         className="titulo_tabela"
  //         scope="col"
  //         onClick={(e) => ordenar("sugestao_arredondamento")}
  //       >
  //         Arredondamento
  //       </TableCell>
  //     </>
  //   );
  // }
  return (
    <>
      <TableCell
        className="titulo_tabela"
        scope="col"
        onClick={(e) => ordenar("sugestao_arredondamento")}
      >
        Arredondamento
      </TableCell>
      <TableCell
        className="titulo_tabela"
        scope="col"
        onClick={(e) => ordenar("tolerancia")}
      >
        Preço Tolerância
      </TableCell>
    </>
  );
}
