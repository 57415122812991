/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      lastName
      email
      telephone
      empresaId
      exportacaoid
      idrole
      profile
      owner
      logo64
      apiurl
      dbschema
      idERP
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lastName
        email
        telephone
        empresaId
        exportacaoid
        idrole
        profile
        owner
        logo64
        apiurl
        dbschema
        idERP
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
