export function difMrgReg(dif) {
  var cor = "";
  if (dif >= 0) {
    cor = "verde";
  }
  if (dif <= 0) {
    cor = "tomato";
  }
  return cor;
}
