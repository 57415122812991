import React from "react";
export function renderCheckboxModule(
  row,
  index,
  bloqueados,
  filhos,
  exportar,
  editados,
  handleSelectField
) {
  if (bloqueados === false) {
    if (
      row.flag_pai_ou_filho === "FILHO" ||
      filhos === "FILHO" ||
      exportar === true ||
      // editados === true ||
      row.analizado == 2
    ) {
      return;
    }
  }
  return (
    <input
      type="checkbox"
      className="box_vlr"
      name="box_vlr"
      checked={row.analizado == 1}
      key={index + "preco_min_max"}
      onChange={(e) => handleSelectField(e, index)}
    />
  );
}
