//Índice Quantitativo - Preço Varejo

import React, { useState, useEffect } from 'react'
import {Auth} from "aws-amplify";
import itensFiltro from '../../Helpers/ItensFiltro';
import Chart from "react-google-charts";




function AnaliseQuantitativo(props) {
    const [analisequant, setAnalisequant] = useState({});
  
    useEffect(() => {

        async function fetchMyAPI() {

            const AnaliseQuantitativoObj = {db_schema: 'pricing_bigbox'}
            const apiAnaliseQuantitativo = await fetch(props.apiUrl + "/gestao-precos-dashboard/analise-quantitativo", {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                method: "POST",
                body: itensFiltro (AnaliseQuantitativoObj)
            });
            const AnaliseQuantitativoAPI = await apiAnaliseQuantitativo.json();

                var data = []
                data.push(['Nome Concorrente', 'Mais Caro', 'Mais Barato', 'Igual'])
                AnaliseQuantitativoAPI.data.forEach(loja => {
                    data.push([loja.nome_loja_concorrente, parseInt(loja.mais_caro), parseInt(loja.mais_barato), parseInt(loja.igual)])

                });
            setAnalisequant(data)

            await Auth.currentAuthenticatedUser()
        }

        fetchMyAPI();
    }, [props.apiUrl])

    
    return (
        <Chart
            width={'100%'}
            height={'300px'}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={analisequant}
            options={{
                // Material design options
                // chart: {
                // title: 'Company Performance',
                // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                // },
            }}
            // For tests
            rootProps={{ 'data-testid': '2' }}
            />
    );
}






  export default  AnaliseQuantitativo;
  

  