import React from "react";
import "./style.css";

export default function DownloadArqCsv({ schema }) {
  const download = async () => {
    window.location =
      process.env.REACT_APP_API + `/api/v2/gestao/file-download-xlsx/${schema}`;
  };

  return (
    <div class="csvDownload" onClick={download}>
      Download XLS
    </div>
  );
}
