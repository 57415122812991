import React, { useState, useCallback } from "react";

import bgImage from "./assets/bg.jpg";
import btnImage from "./assets/btn_industria.png";
import logoImage from "./assets/ind.png";

export default function RightPanel({ isValid }) {
  const [hover, setHover] = useState(false);

  const toggleHover = useCallback(() => {
    setHover((current) => !current);
  }, []);

  return (
    <div
      style={{
        left: "50%",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={logoImage}
            alt="navigate"
            style={{
              height: "40%",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <img
            src={bgImage}
            alt="navigate"
            style={{
              filter: hover ? "none" : "brightness(0.9) blur(1.5px)",
              transform: hover
                ? "rotate(20deg) scale(3.05)"
                : "rotate(20deg) scale(3.02)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transition: "all 250ms ease-in-out",
            }}
          />
        </div>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          style={{
            height: "20%",
            cursor: "pointer",
            position: "absolute",
            zIndex: 3,
            left: "2rem",
            bottom: "2rem",
          }}
        >
          {isValid && (
            <a
              href="https://industria.prixsia.com.br/authentication-demo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={btnImage} alt="navigate" style={{ height: "100%" }} />
            </a>
          )}
          {!isValid && (
            <a>
              <img
                src={btnImage}
                alt="navigate"
                style={{ height: "100%", opacity: "0.3" }}
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
