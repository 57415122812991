import React from "react";

export function renderSelectAllModule(
  exportar,
  editados,
  selectedfiltro,
  selectAll,
  handleSelectAll
) {
  if (
    exportar === false &&
    editados === false &&
    (selectedfiltro.departamento !== null ||
      selectedfiltro.secao !== null ||
      selectedfiltro.grupo !== null ||
      selectedfiltro.sub_grupo !== null ||
      selectedfiltro.papel_categoria !== null ||
      selectedfiltro.fornecedor !== null ||
      selectedfiltro.sensibilidade !== null ||
      selectedfiltro.tem_pesquisa !== null)
  ) {
    return (
      <input
        checked={selectAll}
        type="checkbox"
        onChange={(e) => handleSelectAll(e)}
        className="box_vlr"
      />
    );
  }
  if (selectedfiltro.bandeira !== null || selectedfiltro.cluster !== null) {
    return selectAll === false;
  }
}
