import { withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";

export const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    top: 0,
    left: 0,
    position: "sticky",
    zIndex: 8,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    cursor: "pointer",
  },
  body: {
    minWidth: "50px",
    left: 0,
    position: "sticky",
    display: "table-cell !important",
    zIndex: 6,
  },
}))(TableCell);
